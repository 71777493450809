<template>
  <el-main>
    <page-title show-back-btn />
    <div class="partition-area">
      <ics-company-info page-title="运营方信息" :info-detail="channelDetail" :loading-detail="loading" @submit="submitForms" />
    </div>
  </el-main>
</template>

<script>
import IcsCompanyInfo from '@/components/company-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: { IcsCompanyInfo },
  mixins: [routeEnterMixin],
  data () {
    return {
      channelId: this.$route.query.channelId,
      channelDetail: {}
    }
  },
  created () {
    if (this.channelId) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.company.channel.getChannelDetail({ id: this.channelId }).then(result => {
        const data = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.firm.regAddr) {
          regAddr = data.firm.regAddr.split(',')
          data.firm.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.firm.regAddrDetail]
        }
        if (data.firm.workAddr) {
          workAddr = data.firm.workAddr.split(',')
          data.firm.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.firm.workAddrDetail]
        }
        this.channelDetail = data.firm || {}
      }).finally(() => {
        this.loading.detail = false
      })
    },
    submitForms  (formData) {
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        let data = formData || {}
        let regAddr = ''
        let regAddrDetail = ''
        let workAddr = ''
        let workAddrDetail = ''
        if (data.usEnterpriseAddress !== undefined && data.usEnterpriseAddress.length > 0) {
          const regAddr1 = [data.usEnterpriseAddress[0], data.usEnterpriseAddress[1], data.usEnterpriseAddress[2]]
          regAddr = regAddr1.join(',')
          regAddrDetail = data.usEnterpriseAddress[3]
        }
        if (data.dsEnterpriseAddress !== undefined && data.dsEnterpriseAddress.length > 0) {
          const workAddr1 = [data.dsEnterpriseAddress[0], data.dsEnterpriseAddress[1], data.dsEnterpriseAddress[2]]
          workAddr = workAddr1.join(',')
          workAddrDetail = data.dsEnterpriseAddress[3]
        }
        const record = {
          coreRel: {},
          firm: {
            companyName: data.companyName,
            socialNo: data.socialNo,
            regAmount: data.regAmount,
            legalPerson: data.legalPerson,
            legalIdentity: data.legalIdentity,
            tel: data.tel,
            email: data.email,
            fax: data.fax,
            contactName: data.contactName,
            contactPhone: data.contactPhone,
            net: data.net,
            regAddr: regAddr,
            regAddrDetail: regAddrDetail,
            workAddr: workAddr,
            workAddrDetail: workAddrDetail,
            scope: data.scope,
            remark: data.remark
          },
          coreCapAmountRelList: [],
          coreCapProdRelList: []
        }
        if (this.channelId) {
          record.firm.id = data.id
          this.api.company.channel.updateChannel(record.firm).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('保存成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        } else {
          this.api.company.channel.addChannel(record).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('保存成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
